import { Collapse, Spin } from "antd";
import AOS from "aos";
import axios from "axios";
import { motion } from "framer-motion";
import gsap from "gsap";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import Image from "next/image";
import Links from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-scroll";
import border from "../../../public/border.png";
import reg from "../../../public/reg.png";
import poly from "../../assets/images/hero/poly.png";
import num1 from "../../assets/images/main/01.png";
import num2 from "../../assets/images/main/02.png";
import arrw1 from "../../assets/images/main/arrw1.png";
import award from "../../assets/images/main/award.png";
import brand1 from "../../assets/images/main/brand1.png";
import brand from "../../assets/images/main/brandd.webp";
import brandvnmb from "../../assets/images/main/branddmb.png";
import branden from "../../assets/images/main/branden.webp";
import brandenmb from "../../assets/images/main/brandenmb.png";
import calen from "../../assets/images/main/calen.png";
import cloud from "../../assets/images/main/clound.png";
import icon from "../../assets/images/main/icon.png";
import main1 from "../../assets/images/main/main1.webp";
import tick from "../../assets/images/main/tick.png";
import { LANGUAGES } from "../../constants/other";
import { dataApply, dataHero, dataHotImg, datahot } from "../../mock/hero";
import { newsDetail } from "../../mock/newsDetail";

const RegisterTrial = dynamic(
  () => import("../RegisterTrial/RegisterTrial.presenter")
);
const Popup = dynamic(() => import("../Popup/Popup.presenter"));

const Homepage = () => {
  const { t, i18n } = useTranslation(["translation"]);
  const router = useRouter();

  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { Panel } = Collapse;
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const changeTab = (index: number) => {
    setTab(index);
  };
  const [dataVcb, setDataVcb] = useState<any>();

  const [scroll, setScroll] = useState(false);
  const fadeleft = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get("https://test.wisere.com/api/v1/master-data/currency/exchange-vcb")
      .then((response: any) => {
        const xmlData = response.data?.data;
        const parseVcv = JSON.parse(xmlData);
        setDataVcb(parseVcv);

        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const el = fadeleft.current;
    gsap.fromTo(el, { translateX: "100px" }, { translateX: 0, duration: 0.3 });
    AOS.init();
    window.addEventListener("scroll", handleScroll);
    const e = setTimeout(() => {
      setOpenPopup(!openPopup);
    }, 4e3);
    return () => {
      clearTimeout(e);
    };

    //eslint-disable-next-line
  }, []);

  const handleScroll = () => {
    window.scrollY >= 10 ? setScroll(!0) : setScroll(!1);
  };

  const dataVcbSell = dataVcb?.ExrateList?.Exrate[19]?.$?.Sell;

  let formattedAmount = (dataVcbSell?.replace(/,/g, "") * 9.99).toLocaleString(
    "vi-VN",
    {
      maximumFractionDigits: "vi-VN" ? 0 : 2,
    }
  );

  return (
    <div className="overflow-hidden">
      <>
        <section className=" mb-16 1lg:z-[0] 1lg:relative overflow-hidden 1sm:mb-[2rem]">
          <div className="relative">
            <div className="h-h800 relative z-[-5]">
              <video
                autoPlay
                muted
                loop
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
                className="h-h800 1sm:hidden"
              >
                <source
                  src="https://wasabi-delta.vercel.app/head1.mp4"
                  type="video/mp4"
                />
              </video>
              <video
                autoPlay
                muted
                loop
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
                className="h-h800 hidden 1sm:block"
              >
                <source
                  src="https://wasabi-delta.vercel.app/mobi2.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="absolute top-1/4 left-0/4  w-full  z-[2] 1sm:top-[30%]  ">
              <div className="max-w-[1200px] w-full mx-auto 11xl:px-[2rem] ">
                <motion.h2
                  className="text-p34 text-white font-bold w-[70%] mb-0 22lg:text-[24px] 1sm:w-full 1sm:mb-[0rem] 1sm:text-import20 1sm:font-semibold"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  exit="hide"
                  variants={{
                    hide: {
                      opacity: 0,
                      y: 100,
                    },
                    show: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  {i18n.language === LANGUAGES.en ? (
                    <></>
                  ) : (
                    <>
                      <span className="text-green4 mb-4 capitalize 1sm:mb-[0.5rem]">
                        {t("H1")}
                      </span>
                      <span className="text-green4 mb-2 capitalize block  1sm:mb-[0.5rem]">
                        {t("H11")}
                      </span>
                      <span className="text-green4 mb-4 capitalize block  1sm:mb-[0.5rem]">
                        {t("H111")}
                      </span>
                    </>
                  )}
                </motion.h2>
                <motion.h2
                  className="text-p34 text-white font-bold w-[73%]  22lg:text-[24px] 1sm:w-full 1sm:mb-0 1sm:text-import20 mb-4 1sm:font-semibold"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  exit="hide"
                  variants={{
                    hide: {
                      opacity: 0,
                      y: 100,
                    },
                    show: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  {i18n.language === LANGUAGES.en ? (
                    <>
                      <span className=" text-green4 mb-4 capitalize block 1sm:mb-[0.5rem]">
                        {t("H1")}
                      </span>
                      <span className=" text-green4 mb-4 capitalize block 1sm:mb-[0.5rem]">
                        {t("H1111")}
                      </span>
                      <span className=" text-green4 mb-4 capitalize block 1sm:mb-[0.5rem]">
                        {t("H11")}
                      </span>
                      <span className=" text-green4 mb-4 capitalize block 1sm:mb-[0.5rem]">
                        {t("H111")}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  <span className="text-p32 block 1sm:mb-8 text-white font-bold 1sm:text-import20 1sm:font-semibold">
                    {t(
                      "Wasabi là giải pháp lưu trữ dám mây hấp dẫn nhất thế giới với"
                    )}
                  </span>{" "}
                </motion.h2>

                <motion.div
                  className="flex items-center gap-2 mb-4"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  exit="hide"
                  variants={{
                    hide: {
                      opacity: 0,
                      x: 100,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div className="1sm:w-[2%]">
                    <Image src={poly} alt="" />
                  </div>
                  <p className="text-h5 text-white 22lg:text-[18px] capitalize 1sm:text-import14">
                    {t("Không tốn phí truy xuất dữ liệu")}
                  </p>
                </motion.div>

                <motion.div
                  className="flex items-center gap-2 mb-4 1sm:mb-[2rem]"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  exit="hide"
                  variants={{
                    hide: {
                      opacity: 0,
                      x: 100,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div className="1sm:w-[2%]">
                    <Image src={poly} alt="" />
                  </div>
                  <p className="text-h5 text-white 22lg:text-[18px] capitalize 1sm:text-import14 ">
                    {t("HERO4")}
                  </p>
                </motion.div>
                <motion.div
                  className="flex items-center gap-2 mb-4 1sm:mb-[2rem]"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  exit="hide"
                  variants={{
                    hide: {
                      opacity: 0,
                      x: 100,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div className="1sm:w-[2%]">
                    <Image src={poly} alt="" />
                  </div>
                  <p className="text-h5 text-white 22lg:text-[18px] capitalize 1sm:text-import14 ">
                    {t("HERO444")}
                  </p>
                </motion.div>
                <div className=" items-center gap-2 hidden 1sm:flex">
                  <Links href="/partners" prefetch={false}>
                    <button className="text-but border z-[2] w-[15rem] text-green overflow-hidden rounded-full	 after:transition-all after:opacity-0 after:duration-300 ease-in-out relative h-12 after:content after:bg-green after:left-[-70%] after:top-0 after:w-[15rem] after:h-12 after:absolute after:rounded-full after:z-[-1] hover:after:left-0 hover:after:transition-all hover:after:duration-300 hover:after:opacity-100 hover:text-white hover:border-green">
                      {t("Trở thành đối tác")}
                    </button>
                  </Links>

                  <Links
                    href="/trial-register"
                    prefetch={false}
                    className="text-link cursor-pointer"
                  >
                    <button className="flex ce items-center flex-col bg-green text-but justify-center w-40 text-white rounded-full h-12 relative overflow-hidden after:content after:absolute after:top-0 after:left-[-100%] after:z-2 after:w-[100%] after:h-[100%] after:bg-hover1 after:delay-[5s after:duration-[1s] hover:after:left-[100%] ] 1sm:text-[14px] 1sm:w-[250px] z-[2] cursor-pointer">
                      <p className="text-link">{t("Dùng thử")}</p>
                      <p className="text-link">{t("1TB / 30 ngày")}</p>
                    </button>
                  </Links>
                </div>
              </div>
            </div>
          </div>
        </section>
        <LazyLoad height={500} once={true}>
          <motion.section
            className="max-w-[1200px] w-full mx-auto mb-16 11xl:px-[2rem] 2xl:mb-12 1sm:mb-[2rem]"
            id="solutions"
            viewport={{ once: true }}
            initial="hide"
            whileInView="show"
            exit="hide"
            variants={{
              hide: {
                opacity: 0,
                y: 100,
              },
              show: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                },
              },
            }}
          >
            <div className="mb-16 1sm:mb-[2rem]">
              <motion.h2
                className="text-center text-h2 text-darkPri font-bold 22lg:text-[24px]"
                viewport={{ once: true }}
                initial="hide"
                whileInView="show"
                exit="hide"
                variants={{
                  hide: {
                    opacity: 0,
                    y: 100,
                  },
                  show: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.8,
                      bounce: 0.4,
                    },
                  },
                }}
              >
                {t("HERO5")}
              </motion.h2>
              <motion.p
                className="text-center text-p text-darkPri font-medium 22lg:text-[14px]"
                initial="hide"
                whileInView="show"
                viewport={{ once: true }}
                exit="hide"
                variants={{
                  hide: {
                    opacity: 0,
                    y: 100,
                  },
                  show: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.8,
                    },
                  },
                }}
              >
                {t("HERO6")}
              </motion.p>
            </div>
            <motion.div
              className="flex gap-4 justify-between flex-wrap 2xl:gap-[10px] 1lg:justify-start "
              initial="hide"
              viewport={{ once: true }}
              whileInView="show"
              exit="hide"
              variants={{
                hide: {
                  opacity: 0,
                  y: 100,
                },
                show: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.8,
                    bounce: 0.4,
                  },
                },
              }}
            >
              {dataHero.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className="heroContent overflow-hidden transition-all delay-300 w-width24 p-[1.5rem] bg-grayLight rounded-xl relative after:content after:bg-[url('/hover.webp')] after:w-[288px] after:absolute after:h-full after:top-0  after:bg-no-repeat  after:hover:block after:left-[-100%]  after:opacity-0 after:hover:transition-all z-[5] 1lg:w-[32%] 1lg:p-[1rem] 1sm:w-wFull cursor-pointer hover:after:left-[0%] after:delay-[3s after:duration-[.3s] hover:after:duration-[.3s] hover:after:ease-linear hover:after:opacity-100 hover:after:text-white  1sm:after:bg-[url('/hover2.png')] 1sm:after:w-full 1sm:after:bg-right"
                  >
                    <div className="z-10 relative h-[20rem] 1lg:h-[16rem] 1sm:h-hImpor cursor-pointer hover:text-[white!important]">
                      <div className="mb-6 22lg:mb-[.5rem] z-10 hover:text-[white!important] ">
                        <Image src={item.img} alt="" />
                      </div>
                      <div className="mb-2 z-10">
                        <h2 className="text-p18 font-bold uppercase text-[darkSecon] 22lg:text-[16px]">
                          {t(item.title.toUpperCase())}
                        </h2>
                      </div>
                      <div className="z-10">
                        <p className="text-p text-gray w-full 22lg:text-[14px] font-medium">
                          {t(item.des.toUpperCase())}
                        </p>
                      </div>
                    </div>
                    <Links
                      prefetch={false}
                      href={item.slug}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <a
                        href={item.slug}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="program">
                          <button className="text-grayDark text-p16 font-bold 22lg:text-[14px] z-10 program flex items-center gap-1 mt-auto relative py-[1rem]  w-[180px] bg-transparent h-[30px] rounded-[50rem] justify-center transition-all 1sm:justify-start">
                            {t(item.more.toUpperCase())}
                            <Image src={item.icon} alt="" />
                          </button>
                        </div>
                      </a>
                    </Links>
                  </div>
                  // </Links>
                );
              })}
            </motion.div>
          </motion.section>
          <section className="max-w-[1200px] w-full mx-auto mb-16 overflow-hidden 11xl:px-[2rem] 2xl:mb-6">
            <motion.div
              initial="hide"
              viewport={{ once: true }}
              whileInView="show"
              exit="hide"
              variants={{
                hide: {
                  opacity: 0,
                  x: -200,
                },
                show: {
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                    bounce: 0.4,
                  },
                },
              }}
            >
              <h2 className="text-h3 text-darkPri font-bold capitalize mb-4 2xl:text-[34px] 22lg:text-[24px]">
                {t("HERO23")}
              </h2>
              <p className="text-[18px] text-darkPri font-bold w-[50%] mb-6 2xl:text-[14px]   22lg:text-[14px] 1sm:w-wFull ">
                {t("HERO24")}
              </p>
            </motion.div>
            <div className="flex items-center 1sm:flex-col-reverse">
              <div>
                <motion.div
                  className="flex gap-4 mb-4 "
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  exit="hide"
                  variants={{
                    hide: {
                      opacity: 0,
                      x: 200,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div>
                    <Image src={poly} alt="" />
                  </div>
                  <h2 className="text-darkPri text-p18  ml-1 w-[80%] 2xl:text-[14px] ">
                    <span className="font-medium"> {t("HERO25")}</span>{" "}
                    <Links href="https://status.wasabi.com" prefetch={false}>
                      <a target="_blank" rel="noopener noreferrer">
                        <span className="underline text-green font-bold">
                          {t("HERO26")}
                        </span>
                      </a>
                    </Links>
                    <span className="font-medium"> {t("HERO27")}</span>
                  </h2>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-4"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  exit="hide"
                  variants={{
                    hide: {
                      opacity: 0,
                      x: 200,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div>
                    <Image src={poly} alt="" />
                  </div>
                  <p className="text-darkPri text-p18 inline-flex ml-1 w-[80%] 2xl:text-[14px] font-medium">
                    {t("HERO28")}
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-4"
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0,
                      x: 200,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div>
                    <Image src={poly} alt="" />
                  </div>
                  <p className="text-darkPri text-p18 inline-flex ml-1 w-[80%] 2xl:text-[14px] font-medium">
                    {t("HERO29")}
                  </p>
                </motion.div>
                <motion.div
                  className="flex gap-4 mb-4"
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0,
                      x: 200,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div>
                    <Image src={poly} alt="" />
                  </div>
                  <p className="text-darkPri text-p18 inline-flex ml-1 w-[80%] 2xl:text-[14px] font-medium">
                    {t("HERO30")}
                  </p>
                </motion.div>
                <Links href="https://wasabi.com/about/ " prefetch={false}>
                  <motion.div
                    className="mt-6"
                    initial="hide"
                    whileInView="show"
                    exit="hide"
                    viewport={{ once: true }}
                    variants={{
                      hide: {
                        opacity: 0,
                        scale: 1.1,
                      },
                      show: {
                        opacity: 1,
                        scale: 1,
                        transition: {
                          duration: 0.8,
                          bounce: 0.4,
                        },
                      },
                    }}
                  >
                    <button className="bg-green text-p18 w-[180px] h-[48px] relative text-white rounded-full overflow-hidden after:content after:absolute after:top-0 after:left-[-100%] after:z-2 after:w-[100%] after:h-[100%] after:bg-hover1 after:delay-[5s after:duration-[1s] hover:after:left-[100%] ] 1sm:text-[14px] 1sm:w-[250px] z-[2] cursor-pointer ">
                      {t("MORE")}
                    </button>
                  </motion.div>
                </Links>
              </div>
              <motion.div
                className="1sm:mb-4"
                initial="hide"
                whileInView="show"
                exit="hide"
                viewport={{ once: true }}
                variants={{
                  hide: {
                    opacity: 0,
                    x: -200,
                  },
                  show: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      bounce: 0.4,
                    },
                  },
                }}
              >
                <Image src={main1} alt="" />
              </motion.div>
            </div>
          </section>
          <section className="py-6  bg-grayLi overflow-hidden">
            <div className="py-6 max-w-[1200px] w-full mx-auto overflow-hidden 11xl:px-[2rem] 2xl:py-4">
              <motion.div
                className="mb-16"
                initial="hide"
                whileInView="show"
                exit="hide"
                viewport={{ once: true }}
                variants={{
                  hide: {
                    opacity: 0,
                    y: 100,
                  },
                  show: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.8,
                      bounce: 0.4,
                    },
                  },
                }}
              >
                <h2 className="text-center text-h2 text-darkPri font-bold 2xl:text-[34px]  22lg:text-[24px]">
                  {t("HERO31")}
                </h2>
                <p className="text-center text-p text-darkPri font-medium  22lg:text-[14px]">
                  {t("HERO32")}
                </p>
              </motion.div>
              <div className="flex justify-between gap-16 items-center 22lg:gap-[1.5rem]">
                <motion.div
                  className="w-[65%] 1sm:w-wFull 1sm:hidden"
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0,
                      y: 100,
                    },
                    show: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  {datahot.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className={
                          tab === item.key
                            ? "bg-green rounded-xl p-[1.5rem] flex items-start gap-8 cursor-pointer duration-300 ease-in-out 2xl:p-4 2xl:gap-4"
                            : "bg-white rounded-xl p-[1.5rem] flex items-start gap-8 cursor-pointer relative after:content after:absolute after:w-11/12  after:bg-grayLigh after:bottom-0 after:h-px after:left-left5 mx-auto last:after:hidden 2xl:p-4 2xl:gap-4"
                        }
                        onClick={() => changeTab(item.key)}
                      >
                        <div>
                          {tab === item.key ? (
                            <Image src={item.imgActive} />
                          ) : (
                            <Image src={item.img} alt="" />
                          )}
                        </div>

                        <div className="w-full">
                          <h2
                            className={
                              tab === item.key
                                ? "text-white text-h4 font-bold uppercase mb-2 2xl:text-[16px]"
                                : "text-darkThir text-h4 font-bold uppercase mb-2 2xl:text-[16px]"
                            }
                          >
                            {t(item.title.toUpperCase())}
                          </h2>
                          <div className="flex  gap-2 mb-2">
                            <div className="w-[3%]">
                              <Image src={icon} alt="" />
                            </div>
                            <p
                              className={
                                tab === item.key
                                  ? "text-white text-p font-medium  w-[100%] 2xl:text-[14px]"
                                  : "text-darkThir text-p font-medium  w-[100%] 2xl:text-[14px]"
                              }
                            >
                              <span
                                className={
                                  tab === item.key
                                    ? "font-bold text-white"
                                    : "font-bold text-green"
                                }
                              >
                                {" "}
                                {t(item.des.toUpperCase())}
                              </span>{" "}
                              <span> {t(item.des1.toUpperCase())}</span>
                            </p>
                          </div>
                          <div className="flex  gap-2 mb-2">
                            <div className="w-[3%]">
                              <Image src={icon} alt="" />
                            </div>
                            <p
                              className={
                                tab === item.key
                                  ? "text-white text-p font-medium  2xl:text-[14px]"
                                  : "text-darkThir text-p font-medium  2xl:text-[14px]"
                              }
                            >
                              {t(item.des2.toUpperCase())}
                            </p>
                          </div>
                          {item.des3 && (
                            <div className="flex  gap-2 ">
                              <div className="w-[3%]">
                                <Image src={icon} alt="" />
                              </div>
                              <p
                                className={
                                  tab === item.key
                                    ? "text-white text-p font-medium   w-[100%] 2xl:text-[14px]"
                                    : "text-darkThir text-p font-medium   w-[100%] 2xl:text-[14px]"
                                }
                              >
                                {t(item.des3.toUpperCase())}
                              </p>
                            </div>
                          )}
                          {item.des4 && (
                            <div className="flex items-center gap-2">
                              <div className="w-[3%]">
                                <Image src={icon} alt="" />
                              </div>
                              <p
                                className={
                                  tab === item.key
                                    ? "text-white text-p font-medium  w-[100%] 2xl:text-[14px]"
                                    : "text-darkThir text-p font-medium  w-[100%] 2xl:text-[14px]"
                                }
                              >
                                {t(item.des4.toUpperCase())}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </motion.div>
                <div className="w-6/12 1sm:w-wFull hidden 1sm:block">
                  <Collapse defaultActiveKey={0} accordion bordered={false}>
                    {datahot.map((item, idx) => {
                      return (
                        <Panel
                          key={idx}
                          className="panelHome"
                          showArrow={false}
                          header={
                            <>
                              <div
                                className={
                                  tab === item.key
                                    ? "bg-green rounded-xl p-10 flex items-start gap-8 cursor-pointer duration-300 ease-in-out 2xl:p-4 2xl:gap-4 relative "
                                    : "bg-white rounded-xl p-10 flex items-start gap-8 cursor-pointer relative after:content after:absolute after:w-11/12  after:bg-grayLigh after:bottom-0 after:h-px after:left-left5 mx-auto last:after:hidden 2xl:p-4 2xl:gap-4 overflow-hidden"
                                }
                                onClick={() => changeTab(item.key)}
                              >
                                <div>
                                  {tab === item.key ? (
                                    <Image src={item.imgActive} alt="" />
                                  ) : (
                                    <Image src={item.img} alt="" />
                                  )}
                                </div>
                                <div className="w-full">
                                  <h2
                                    className={
                                      tab === item.key
                                        ? "text-white text-h4 font-bold uppercase mb-2 2xl:text-[16px]"
                                        : "text-darkThir text-h4 font-bold uppercase mb-2 2xl:text-[16px]"
                                    }
                                  >
                                    {t(item.title.toUpperCase())}
                                  </h2>
                                  <div className="flex  gap-2 mb-2">
                                    <div className="w-[3%]">
                                      <Image src={icon} alt="" />
                                    </div>
                                    <div className="w-[100%]">
                                      <h2>
                                        <span
                                          className={
                                            tab === item.key
                                              ? "text-white text-p font-medium mb-2 w-[100%] 2xl:text-[14px] mr-[2px]"
                                              : "text-green2 text-p font-medium mb-2 w-[100%] 2xl:text-[14px] mr-[2px]"
                                          }
                                        >
                                          {t(item.des.toUpperCase())}
                                        </span>
                                        <span
                                          className={
                                            tab === item.key
                                              ? "text-white text-p font-medium mb-2 w-[100%] 2xl:text-[14px]"
                                              : "text-darkThir text-p font-medium mb-2 w-[100%] 2xl:text-[14px]"
                                          }
                                        >
                                          {t(item.des1.toUpperCase())}
                                        </span>
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="flex  gap-2 mb-2">
                                    <div className="w-[3%]">
                                      <Image src={icon} alt="" />
                                    </div>
                                    <div className="w-[100%]">
                                      <p
                                        className={
                                          tab === item.key
                                            ? "text-white text-p font-medium  mb-2 2xl:text-[14px]"
                                            : "text-darkThir text-p font-medium  mb-2 2xl:text-[14px]"
                                        }
                                      >
                                        {t(item.des2.toUpperCase())}
                                      </p>
                                    </div>
                                  </div>
                                  {item.des3 && (
                                    <div className="flex  gap-2 ">
                                      <div className="w-[3%]">
                                        <Image src={icon} alt="" />
                                      </div>
                                      <div className="w-[90%]">
                                        <p
                                          className={
                                            tab === item.key
                                              ? "text-white text-p font-medium  mb-2 w-[90%] 2xl:text-[14px]"
                                              : "text-darkThir text-p font-medium  mb-2 w-[90%] 2xl:text-[14px]"
                                          }
                                        >
                                          {t(item.des3.toUpperCase())}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {item.des4 && (
                                    <div className="flex items-center gap-2">
                                      <div className="w-[3%]">
                                        <Image src={icon} alt="" />
                                      </div>
                                      <div className="w-[100%]">
                                        <p
                                          className={
                                            tab === item.key
                                              ? "text-white text-p font-medium  mb-2 w-[100%] 2xl:text-[14px]"
                                              : "text-darkThir text-p font-medium  mb-2 w-[100%] 2xl:text-[14px]"
                                          }
                                        >
                                          {t(item.des4.toUpperCase())}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          }
                        >
                          <div className="1sm:py-[1rem]">
                            <Image src={item.imgMain} alt="" />
                          </div>
                        </Panel>
                      );
                    })}
                  </Collapse>
                </div>
                <motion.div
                  className="w-6/12 1sm:w-wFull 1sm:hidden"
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0,
                      x: 100,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  {dataHotImg.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className={tab === item.key ? "block" : "hidden"}
                      >
                        {i18n.language === LANGUAGES.en ? (
                          <Image src={item.img} alt="" />
                        ) : (
                          <Image src={item.imgVn} alt="" />
                        )}
                      </div>
                    );
                  })}
                </motion.div>
              </div>
            </div>
          </section>
        </LazyLoad>

        <LazyLoad height={500} once={true}>
          <motion.section
            className="bg-[url('/bg.webp')] overflow-hidden 1sm:bg-[url('/bgmb.webp')]"
            initial="hide"
            whileInView="show"
            exit="hide"
            viewport={{ once: true }}
            variants={{
              hide: {
                opacity: 0.5,
              },
              show: {
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 0.4,
                },
              },
            }}
          >
            <div className="py-20 max-w-[1200px] w-full mx-auto 11xl:px-[2rem] 1sm:pb-0 22lg:pb-[0rem]">
              <motion.div
                initial="hide"
                whileInView="show"
                exit="hide"
                viewport={{ once: true }}
                variants={{
                  hide: {
                    opacity: 0,
                    x: 200,
                  },
                  show: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      bounce: 0.4,
                    },
                  },
                }}
              >
                <h2 className="text-h2 text-white font-bold capitalize w-[80%]  1lg:w-[95%] 2xl:text-[44px] 22lg:text-[24px] 1sm:text-center 1sm:w-wFull">
                  {t("HERO33")}
                </h2>
                <h2 className="text-h2 text-white font-bold capitalize w-[80%] mb-20 1lg:w-[95%] 2xl:text-[44px] 22lg:text-[24px] 1sm:text-center 1sm:w-wFull 1sm:mb-[5.5rem] ">
                  {t("HERO333")}
                </h2>
              </motion.div>
              <div className="flex gap-[5rem] 1lg:gap-[1rem] 1sm:flex-col-reverse 1sm:gap-[0rem!important]">
                <motion.div
                  className="w-[85%] 1sm:w-full"
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0,
                    },
                    show: {
                      opacity: 1,

                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div className="relative w-full h-[50%] mb-16 1sm:mb-[6rem] 1lg:h-[35%] 2sm:mb-[4rem!important]">
                    <div className="h-full absolute left-[-1%] top-[-17%] z-[1]  1sm:mb-0  1xl:left-[-5%]  1sm:hidden 1lg:top-[-20%!important] 1lg:left-[-14%!important] ]">
                      <Image src={border} alt="" />
                    </div>
                    <div className="hidden h-full absolute left-[3%] top-[-28%] z-[1]  1sm:block   2sm:left-[-5%] 1sm:top-[-65%] 2sm:top-[-36%!important] 4sm:top-[-28%!important] 4sm:mt-[1rem]">
                      <Image src={reg} alt="" />
                    </div>
                    <p className="text-h5 text-white font-medium mb-[18px] w-[90%] z-[2] relative   1lg:text-import16   22lg:text-import14 1lg:w-[100%!important] 1sm:text-import14 1sm:w-wFull  1sm:px-[3rem] 2sm:px-[0rem!important] 22lg:w-wFull  1sm:hidden pl-[5rem] 1xl:pl-[3rem] 1xl:w-[85%] 1lg:pl-[0rem!important]">
                      {t("HERO34")}
                    </p>
                    <p className="hidden 1sm:block text-[14px] text-white font-medium mb-0 w-[90%] mx-auto z-[2] relative 1sm:w-[83%] 1sm:mx-[2rem] 3sm:w-[95%!important] 3sm:ml-[0.5rem!important]">
                      {t("HERO34")}
                    </p>
                  </div>

                  <motion.div
                    className="mb-[2rem] 1lg:mb-[0rem] 1sm:pt-0 "
                    initial="hide"
                    whileInView="show"
                    exit="hide"
                    viewport={{ once: true }}
                    variants={{
                      hide: {
                        opacity: 0.8,
                        y: 50,
                      },
                      show: {
                        opacity: 1,
                        y: 0,
                        transition: {
                          duration: 0.8,
                          bounce: 0.4,
                        },
                      },
                    }}
                  >
                    <p className="text-p text-white font-semibold mb-4 22lg:text-[14px] text-center uppercase">
                      {t("HERO35")}
                    </p>
                    <div className="1lg:pb-[3rem] 1sm:hidden">
                      {i18n.language === LANGUAGES.vn ? (
                        <Image src={brand} alt="" />
                      ) : (
                        <Image src={branden} alt="" />
                      )}
                    </div>
                    <div className="hidden 1lg:pb-[3rem] 1sm:block 1sm:text-center">
                      {i18n.language === LANGUAGES.vn ? (
                        <Image src={brandvnmb} alt="" />
                      ) : (
                        <Image src={brandenmb} alt="" />
                      )}
                    </div>
                  </motion.div>
                </motion.div>
                <motion.div
                  className="w-[50%] 1lg:w-[90%] 1sm:w-wFull indus 1sm:mb-[0rem!important]"
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0.8,
                      x: -200,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div className="mb-14">
                    <Image src={award} alt="" />
                  </div>
                  <Image src={brand1} alt="" />
                </motion.div>
              </div>
            </div>
          </motion.section>
        </LazyLoad>

        <motion.section
          className="bg-[url('/oke.webp')] bg-no-repeat mb-16 overflow-hidden 11xl:px-[2rem] 1sm:bg-[url('/bg22.webp')] 1sm:mb-[1rem] 1sm:bg-contain"
          initial="hide"
          whileInView="show"
          exit="hide"
          viewport={{ once: true }}
          variants={{
            hide: {
              opacity: 0.8,
              zoom: 0.8,
            },
            show: {
              opacity: 1,
              zoom: 1,
              transition: {
                duration: 0.8,
                bounce: 0.4,
              },
            },
          }}
        >
          <div className="pt-20 max-w-[1200px] w-full mx-auto flex 1sm:pt-[22rem] 2sm:pt-[14rem!important]">
            <div className="w-[40%] 1lg:w-[35%] 22lg:hidden"></div>
            <div className="w-[60%] 22lg:w-full">
              <motion.div
                className="pb-12"
                initial="hide"
                whileInView="show"
                exit="hide"
                viewport={{ once: true }}
                variants={{
                  hide: {
                    opacity: 0.5,
                    y: 100,
                  },
                  show: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.8,
                      bounce: 0.4,
                    },
                  },
                }}
              >
                <h2
                  className="text-white text-h2 font-bold capitalize 1lg:text-[44px] 22lg:text-import24 22lg:text-center"
                  id="pricing"
                >
                  <span> {t("HERO36")} </span>
                  <span className="text-green1">{t("HERO37")} </span>
                  <span className="text-green1">{t("HERO38")}</span>
                </h2>
                <h2 className="text-green1 text-h2 font-bold capitalize 1lg:text-[44px] 22lg:text-import24 22lg:text-center"></h2>
              </motion.div>
              <div className=" bg-white rounded-t-xl w-[600px] shadow-[0_27px_49px_-11px_rgba(0,0,0,0.06)] 22lg:w-full 1sm:pb-[2rem]">
                <div className="bg-green px-8 rounded-t-lg flex py-4 1sm:items-baseline justify-center gap-4  2sm:px-[1rem] items-baseline">
                  {i18n.language === LANGUAGES.vn ? (
                    <h2 className="text-white ">
                      <div className="flex items-start">
                        <span className="text-h48 1sm:text-[28px] font-bold 2sm:text-import22">
                          {" "}
                          {formattedAmount === "NaN"
                            ? "253.546"
                            : formattedAmount}
                        </span>
                        <span className="text-h6 1sm:text-p18 font-bold 2sm:text-import22">
                          {t("HERO3999")}
                        </span>
                      </div>
                      {/* <span className="text-h4 font-semibold">
                    {t("HERO399")}
                  </span> */}
                    </h2>
                  ) : (
                    <>
                      <h2 className="text-white ">
                        <div className="flex items-start">
                          <span className="text-h48 1sm:text-[28px] font-bold 2sm:text-import22">
                            {" "}
                            {formattedAmount === "NaN"
                              ? "253.546"
                              : formattedAmount}
                          </span>
                          <span className="text-h6 1sm:text-p18 font-bold 2sm:text-import22">
                            {t("HERO3999")}
                          </span>
                        </div>
                      </h2>
                    </>
                  )}
                  <p className="w-full text-white text-h5 pb-[10px] 1sm:text-import14 2sm:text-import14">
                    {t("HERO40")}
                  </p>
                </div>
                <div className="px-8 pt-8 1sm:px-[1rem] 2sm:px-[1rem] 2sm:pt-[1rem]">
                  <div className="flex gap-6 mb-6 1sm:mb-2 2sm:items-start 2sm:gap-[.5rem]">
                    <div className="2sm:w-[10%]">
                      <Image src={tick} alt="" />
                    </div>
                    <h2 className=" ">
                      <span className="text-green font-bold text-h6 1sm:text-[16px] 2sm:text-import14">
                        {" "}
                        {t("HERO41")}
                      </span>
                      <span className="1sm:text-[16px] 2sm:text-import14 text-h6 font-medium text-darkfou">
                        {" "}
                        {t("HERO42")}
                      </span>
                    </h2>
                  </div>
                  <div className="flex gap-6 mb-6 1sm:mb-2 2sm:items-start 2sm:gap-[.5rem]">
                    <div className="2sm:w-[10%]">
                      <Image src={tick} alt="" />
                    </div>
                    <h2 className="text-darkfou ">
                      <span className="text-green font-bold 1sm:text-[16px] 2sm:text-import14 text-h6">
                        {" "}
                        {t("HERO41")}
                      </span>
                      <span className="1sm:text-[16px] 2sm:text-import14 text-h6 font-medium">
                        {" "}
                        {t("HERO43")}
                      </span>
                    </h2>
                  </div>
                  <div className="flex gap-6 mb-6 1sm:mb-2 2sm:items-start 2sm:gap-[.5rem]">
                    <div className="2sm:w-[10%]">
                      <Image src={tick} alt="" />
                    </div>
                    <h2 className="text-darkfou ">
                      <span className="text-green font-bold 1sm:text-[16px] 2sm:text-import14 text-h6">
                        {" "}
                        {t("HERO44")}
                      </span>
                      <span className="1sm:text-[16px] 2sm:text-import14 text-h6 font-medium">
                        {" "}
                        {t("HERO45")}
                      </span>
                    </h2>
                  </div>
                  <div className="flex gap-6 mb-6 1sm:mb-6 2sm:items-start 2sm:gap-[.5rem]">
                    <div className="2sm:w-[10%]">
                      <Image src={tick} alt="" />
                    </div>
                    <h2 className="text-darkfou ">
                      <span className="text-green font-bold 1sm:text-[16px] 2sm:text-import14 text-h6">
                        {" "}
                        {t("HERO41")}
                      </span>
                      <span className="1sm:text-[16px] 2sm:text-import14 text-h6 font-medium">
                        {" "}
                        {t("HERO46")}{" "}
                      </span>
                      <span
                        className="text-blue underline text-h6 cursor-pointer 1sm:text-[16px] 2sm:text-import14"
                        onClick={() => setOpen(!open)}
                      >
                        {t("HERO47")}
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="px-8 pb-6 1sm:text-center 1sm:pb-[1rem]">
                  <Link
                    href="/trial-register"
                    activeClass="active"
                    to="regis"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={100}
                    className="text-link cursor-pointer"
                  >
                    <button className="bg-green w-[180px] h-[40px] text-white rounded-[50px] relative overflow-hidden after:content after:absolute after:top-0 after:left-[-100%] after:z-2 after:w-[100%] after:h-[100%] after:bg-hover1 after:delay-[5s after:duration-[1s] hover:after:left-[100%] ] 1sm:text-[14px] 1sm:w-[250px] z-[2] cursor-pointer">
                      {t("HERO48")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </motion.section>

        <section className="mb-12 11xl:px-[2rem] 1sm:px-[1rem!important]">
          <div className="bg-[url('/bg24.webp')] bg-no-repeat  mx-auto text-center bg-center  rounded-[2rem] bg-cover  max-w-[1200px] w-full">
            <div className="text-left p-12 1sm:p-8">
              <div className="border-b-[1px] w-[50%] border-greyy pb-4 box-shadow-[0_4px_7px_rgba(22, 59, 83, 0.59)] 1sm:w-full">
                <h2 className="text-[38px] font-bold capitalize text-white 11xl:text-[24px] 1sm:text-import22">
                  {t("REGIS1")}
                </h2>
                <h2 className="text-[38px] font-bold capitalize  11xl:text-[24px]  1sm:text-import22">
                  <span className="text-white"> {t("REGIS2")}</span>{" "}
                  <span className="text-green5"></span>
                </h2>
              </div>
              <div className="pt-4 mb-4">
                {i18n.language === LANGUAGES.vn ? (
                  <>
                    <p className="text-[24px] font-medium capitalize text-white 11xl:text-[18px]">
                      {t("REGIS3")}
                    </p>
                    <p className="text-[32px] font-bold capitalize text-green5 11xl:text-[18px]  inline">
                      {t("REGIS4")} 10%
                    </p>
                  </>
                ) : (
                  <div className="flex items-center gap-2">
                    <p className="text-[24px] font-medium capitalize text-white 11xl:text-[18px]">
                      {t("REGIS3")}
                    </p>
                    <p className="text-[32px] font-bold capitalize text-green5 11xl:text-[18px]">
                      {t("REGIS4")} 10%
                    </p>
                  </div>
                )}
              </div>
              <div>
                <Links href="trial-register" prefetch={false}>
                  <button className="w-[220px] h-[48px] bg-green text-white rounded-[50px] relative overflow-hidden after:content after:absolute after:top-0 after:left-[-100%] after:z-2 after:w-[100%] after:h-[100%] after:bg-hover1 after:delay-[5s after:duration-[1s] hover:after:left-[100%] ] 1sm:text-[14px] 1sm:w-[250px] z-[2] cursor-pointer 3sm:w-[100%!important]">
                    {t("REGIS5")}
                  </button>
                </Links>
              </div>
            </div>
          </div>
        </section>

        <section className="max-w-[1200px] w-full mx-auto overflow-hidden 11xl:px-[2rem]">
          <div>
            <motion.div
              initial="hide"
              whileInView="show"
              exit="hide"
              viewport={{ once: true }}
              variants={{
                hide: {
                  opacity: 0.5,
                  y: 100,
                },
                show: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.8,
                    bounce: 0.4,
                  },
                },
              }}
            >
              <h2 className="text-darkPri text-h2 mb-16 capitalize font-bold text-center 22lg:text-[24px] 1sm:pb-[2rem] 1sm:mb-0">
                {t("HERO49")}
              </h2>
            </motion.div>
            <div className="flex justify-between mb-16 1sm:flex-col 1sm:mb-8">
              <motion.div
                className="w-[60%] 1sm:w-full "
                initial="hide"
                whileInView="show"
                exit="hide"
                viewport={{ once: true }}
                variants={{
                  hide: {
                    opacity: 0.5,
                    x: 100,
                  },
                  show: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                      bounce: 0.4,
                    },
                  },
                }}
              >
                <div className="border-r-[1px] border-green 1sm:border-b-[1px] 1sm:border-r-0 1sm:pb-[2rem]">
                  <div className="mb-2 1sm:w-[15%]">
                    <Image src={calen} alt="" />
                  </div>
                  <div className="flex items-center gap-6">
                    <h3 className="uppercase text-green text-h5 font-bold 22lg:text-p18 w-[100%] 1sm:text-p20">
                      {t("HERO50")}
                    </h3>
                    <div className=" 1lg:pr-[2rem] 1sm:pr-pl pr-[2rem] w-[7rem] 1sm:w-[5rem] 1sm:h-0">
                      <Image src={num1} alt="" />
                    </div>
                  </div>
                  <div className="flex gap-4 my-4 1sm:my-[.5rem!important]">
                    <div>
                      <Image src={icon} alt="" />
                    </div>
                    <p className="font-medium 22lg:text-[14px]">
                      {t("HERO51")}
                    </p>
                  </div>
                  <div className="flex gap-4 my-4 1sm:my-[.5rem!important]">
                    <div>
                      <Image src={icon} alt="" />
                    </div>
                    <p className="font-medium 22lg:text-[14px]">
                      {t("HERO52")}
                    </p>
                  </div>
                  <div className="flex gap-4 my-4 1sm:my-[.5rem!important]">
                    <div>
                      <Image src={icon} alt="" />
                    </div>
                    <p className="font-medium 22lg:text-[14px]">
                      {t("HERO53")}
                    </p>
                  </div>
                </div>
              </motion.div>
              <div className="w-[70%] pl-[3rem] 1sm:w-wFull 1sm:pl-0 1sm:pt-[2rem] 22lg:w-[50%]">
                <motion.div
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0.5,
                      x: -100,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div className="mb-2 1sm:w-[18%]">
                    <Image src={cloud} alt="" />
                  </div>
                  <div className="flex items-center gap-6">
                    <h3 className="uppercase text-green text-h5 font-bold 22lg:text-p18 w-[100%] 1sm:text-p20">
                      {t("HERO54")}
                    </h3>
                    <div className="1lg:pr-[2rem] 1sm:pr-pl pr-[2rem] w-[7rem] 1sm:w-[5rem] 1sm:h-0">
                      <Image src={num2} alt="" />
                    </div>
                  </div>
                  <div className="flex gap-4 my-4 1sm:my-[.5rem!important]">
                    <div>
                      <Image src={icon} alt="" />
                    </div>
                    <p className="font-medium 22lg:text-[14px]">
                      {t("HERO55")}
                    </p>
                  </div>
                  <div className="flex gap-4 my-4 1sm:my-[.5rem!important]">
                    <div>
                      <Image src={icon} alt="" />
                    </div>
                    <p className="font-medium 22lg:text-[14px]">
                      {t("HERO56")}
                    </p>
                  </div>
                  <div className="flex gap-4 my-4 1sm:my-[.5rem!important]">
                    <div>
                      <Image src={icon} alt="" />
                    </div>
                    <p className="font-medium 22lg:text-[14px]">
                      {t("HERO57")}
                    </p>
                  </div>
                  <div
                    className="flex gap-4 my-4 1sm:my-[.5rem!important]"
                    id="production"
                  >
                    <div>
                      <Image src={icon} alt="" />
                    </div>
                    <p className="font-medium 22lg:text-[14px]">
                      {t("HERO58")}
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        <LazyLoad offset={10} once={true}>
          <section className="mb-0 overflow-hidden">
            <motion.div
              className="bg-[url('/applyBg2.webp')] bg-no-repeat  mx-auto w-[75%] text-center bg-center h-[63rem]  rounded-[2rem] bg-cover  2xxl:w-[95%] 1xl:w-[98%!important] 22lg:w-full  1sm:bg-[url('/bg9.webp')] 1sm:pt-[0rem]  1sm:h-auto 1sm:max-w-[100%] 1sm:pb-[12rem!important] 1sm:mb-0 overflow-hidden 1lg:bg-bottom 1lg:pb-[8rem]"
              initial="hide"
              whileInView="show"
              exit="hide"
              viewport={{ once: true }}
              variants={{
                hide: {
                  opacity: 0.8,
                  scale: 1.2,
                },
                show: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.8,
                    bounce: 0.4,
                  },
                },
              }}
            >
              <div className=" mx-auto w-full py-[3rem] max-w-[1200px]  11xl:px-[2rem] 1sm:py-[2rem] 1sm:mb-0 1sm:px-[2rem] 2sm:px-p2 1xl:pt-[2rem] 1xl:pb-0 1sm:bg-top overflow-hidden">
                <div className="mx-auto w-full 1sm:pt-8">
                  <h2 className="text-white text-h2 mb-4 capitalize font-bold text-left 22lg:text-[24px] ">
                    {t("HERO59")}
                  </h2>
                  <p className="text-white text-p  font-medium text-left mb-4 22lg:text-[14px ] 1sm:mb-8">
                    {t("HERO60")}
                  </p>
                </div>
                <div className="flex justify-between flex-wrap mx-auto w-full 1sm:flex-col 1sm:pb-[1rem] overflow-hidden 1sm:gap-[2rem] ">
                  {dataApply.map((item, idx) => {
                    return (
                      <Links
                        prefetch={true}
                        href={`/apply/${item.slug}`}
                        key={idx}
                        className="cursor-pointer"
                      >
                        <div className="w-[33%] flex flex-col items-start 1sm:w-wFull cursor-pointer 1sm:pb-[0rem]">
                          <div className="mt-[2rem] 1sm:mt-0">
                            <Image src={item.img} alt="" />
                          </div>
                          <div className="h-[4rem] 1sm:h-[2rem!important] 1lg:h-[3rem]">
                            <h2 className="text-left text-h4 uppercase font-bold text-white  mb-auto 1lg:text-[14px]">
                              {t(item.title)}
                            </h2>
                            {item.title1 && (
                              <h2 className="text-left text-h4 uppercase font-bold text-white  mb-auto 1lg:text-[16px]">
                                {t(item.title1)}
                              </h2>
                            )}
                          </div>
                          <div className="">
                            <div className="text-left">
                              <p className="w-[80%] text-white text-p font-normal mb-8 h-[4rem] 1sm:text-[14px] 1sm:w-full 1lg:text-[14px] 1lg:h-[5rem] 1sm:h-[2rem!important]">
                                {t(item.des)}
                              </p>
                            </div>
                            <div className="flex gap-2 items-center mt-auto">
                              <span className="text-white font-bold text-p 1lg:text-[14px]">
                                {t(item.more)}
                              </span>
                              <Image src={arrw1} alt="" />
                            </div>
                          </div>
                        </div>
                      </Links>
                    );
                  })}
                </div>
              </div>
            </motion.div>
          </section>
        </LazyLoad>

        <LazyLoad height={50} offset={10} once={true}>
          <section className="mb-[80px] overflow-hidden 1sm:mt-[-6rem] 1sm:mb-[2rem] mt-[-5rem]">
            <div className="max-w-[1200px] w-full mx-auto 11xl:px-[2rem]">
              <motion.div
                className="mb-[76px] 22lg:mb-[30px]"
                initial="hide"
                whileInView="show"
                exit="hide"
                viewport={{ once: true }}
                variants={{
                  hide: {
                    opacity: 0.8,
                    y: 100,
                  },
                  show: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.8,
                      bounce: 0.4,
                    },
                  },
                }}
              >
                <h2 className="text-darkPri text-h2 font-bold text-center 22lg:text-[24px]">
                  {t("HERO74")}
                </h2>
                <p className="text-darkPri text-p font-medium text-center 22lg:text-[14px]">
                  {t("HERO75")}
                </p>
              </motion.div>
              <div className="flex justify-between 1lg:gap-[1rem] 1sm:flex-col">
                <motion.div
                  className="w-[60%] 1sm:w-wFull pl-4 pr-4 max-w-[480px] cursor-pointer"
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0.8,
                      x: 100,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                  onClick={() =>
                    router.push(`news-detail/${newsDetail[0].slug}`)
                  }
                >
                  <div>
                    <div>
                      <img
                        src={`/${newsDetail[0].image}`}
                        alt=""
                        style={{ borderRadius: 6 }}
                      />
                    </div>
                    <div className="mt-4 mb-[.5rem] 1lg:w-[100%]">
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: newsDetail[0].title,
                        }}
                        className="text-darkSecon font-bold text-h4 1lg:text-[18px]"
                      ></h2>
                    </div>
                    <div className="1lg:w-[100%]">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: `${newsDetail[0].content.slice(0, 290)}...`,
                        }}
                        className="text-gray font-medium text-p 1lg:text-[14px]"
                      ></p>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="w-[50%] 1sm:w-wFull"
                  initial="hide"
                  whileInView="show"
                  exit="hide"
                  viewport={{ once: true }}
                  variants={{
                    hide: {
                      opacity: 0.8,
                      x: -100,
                    },
                    show: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                        bounce: 0.4,
                      },
                    },
                  }}
                >
                  <div>
                    {newsDetail.slice(1, 6).map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          onClick={() =>
                            router.push(`news-detail/${item.slug}`)
                          }
                          className="cursor-pointer"
                        >
                          <div className="flex justify-between gap-4 mb-8 ">
                            <div className="flex flex-col items-center bg-green text-white font-bold w-[70px] h-[70px] p-[8px] rounded">
                              <p className="text-p18 4sm:text-import14">
                                {item.day.split(" ")[0]}
                              </p>
                              <p className="text-link 4sm:text-import12">
                                {`Thg ${item.day.split(" ")[2].slice(0, -1)}`}
                              </p>
                            </div>
                            <div className="w-[100%] pb-[20px] relative after:content after:w-full after:h-[1px] after:bg-grayLight2 after:absolute after:bottom-0 last">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: `${item.title.slice(0, 200)}...`,
                                }}
                                className="text-darkSecon font-semibold text-p18 1lg:text-[16px]"
                              ></p>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: `${item.content.slice(0, 200)}...`,
                                }}
                                className="text-gray text-p 1lg:text-[14px] font-light"
                              ></p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="1sm:hidden">
                    <Links href="/news" prefetch={false}>
                      <button className="w-[220px] h-[48px] bg-green text-white rounded-[50px] relative overflow-hidden after:content after:absolute after:top-0 after:left-[-100%] after:z-2 after:w-[100%] after:h-[100%] after:bg-hover1 after:delay-[5s after:duration-[1s] hover:after:left-[100%] ] 1sm:text-[14px] 1sm:w-[250px] z-[2] cursor-pointer">
                        {t("HERO76")}
                      </button>
                    </Links>
                  </div>
                </motion.div>
              </div>
            </div>
          </section>
        </LazyLoad>

        <RegisterTrial />
      </>

      {open && (
        <div>
          <Popup setOpen={() => setOpen(false)} />
        </div>
      )}
    </div>
  );
};

export default Homepage;
